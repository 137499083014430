import { execute } from '@/config/graphql.config';

// Queries
import fetchPageByHandle from '../graphql/fetchPageByHandle.query.graphql';

export function getPageByHandle(pageHandle: string) {
  const variables = {
    query: `handle:${pageHandle}`
  };

  return execute(fetchPageByHandle, variables)
    .then(result => result.data.pages.edges[0].node.body);
}
