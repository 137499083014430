
import { Component, Vue, Watch } from 'vue-property-decorator';

// Config
import i18n from "@/config/i18n.config";
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import LoadingSpinner from '@/components/loading-spinner.component.vue';

// Resources
import { getPageByHandle } from "@/resources/pages.resource";

@Component({
  components: {
    LoadingSpinner
  }
})
export default class LegalModal extends Vue {

  content: string | null = null;
  loaded = false;

  get title() {
    return {
      'terms-conditions': this.$t('Views.Legal.TermsConditions'),
      'privacy-policy': this.$t('Views.Legal.PrivacyPolicy'),
      'returns-exchanges': this.$t('Views.Legal.ReturnsExchanges'),
    }[this.$route.params.page]
  }

  get localizedContent () {
    if (this.content !== null) {
      const element = document.createElement('html');
      element.innerHTML = this.content;

      const localizedSection = element.querySelectorAll(`[lang="${i18n.locale}"]`);

      if (localizedSection.length > 0) {
        return localizedSection[0].innerHTML;
      }
      else {
        return this.content;
      }
    }

    return '';
  }

  protected created() {
    this.loadPage();

    track(TrackingEventType.ViewContent, {
      content_category: RouteNames.Legal,
      content_name: this.$route.params.page
    });
  }

  @Watch('$route.params.page')
  loadPage(): void {
      this.loaded = false;
      getPageByHandle(this.$route.params.page)
        .then(content => {
          this.content = content;
          this.loaded = true;
        });
  }

}
